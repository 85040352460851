import { IUseOrderStatusChecks, IUserOrderStatusChecksParams } from '../models/OrderStatusesHook';

import { ORDER_STATUS, ORDER_SHIPPING_TYPES } from '../utils/orderStatus';
import { isPastDate } from '../utils/funcs';

/**
 * Hook to set the status and the warning of the checks
 * @param expectedDeliveryDate - The expected delivery date
 * @param isDelayed - If the order is delayed or not
 * @param orderStatus - The status of the order
 * @param orderShippingType - The shiping type of the order
 * @param deliveryDate - The delivery date of the order (as given by carrier)
 */
function useOrderStatusChecks({
  expectedDeliveryDate,
  isDelayed,
  orderStatus,
  orderShippingType,
  deliveryDate,
}: IUserOrderStatusChecksParams): IUseOrderStatusChecks {
  const isDeliveryDateInThePast = isPastDate(expectedDeliveryDate);
  const isMailOrderOrExpress =
    orderShippingType === ORDER_SHIPPING_TYPES.mailOrder || orderShippingType === ORDER_SHIPPING_TYPES.express;

  const checksStatus = { Created: false, InProgress: false, Shipped: false, Delivered: false };
  const checksWarnings = { Created: false, InProgress: false, Shipped: false, Delivered: false };

  if (orderStatus === ORDER_STATUS.created) {
    checksStatus.Created = true;
  }

  if (orderStatus === ORDER_STATUS.inProgress) {
    checksStatus.Created = true;
    checksStatus.InProgress = true;
  }

  if (isDelayed) {
    checksWarnings.Shipped = true;
  }

  if (orderStatus === ORDER_STATUS.shipped) {
    checksStatus.Created = true;
    checksStatus.InProgress = true;
    checksStatus.Shipped = true;

    checksWarnings.Shipped = false;
  }

  if (
    orderStatus === ORDER_STATUS.delivered ||
    (!isMailOrderOrExpress && isDeliveryDateInThePast && orderStatus === ORDER_STATUS.shipped) ||
    (isMailOrderOrExpress && !!deliveryDate && orderStatus === ORDER_STATUS.shipped)
  ) {
    checksStatus.Created = true;
    checksStatus.InProgress = true;
    checksStatus.Shipped = true;
    checksStatus.Delivered = true;

    checksWarnings.Shipped = false;
  }

  return { checksStatus, checksWarnings };
}

export default useOrderStatusChecks;
