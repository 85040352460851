// eslint-disable-next-line
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OrderItemTextsProps } from '../features/components/OrderItemTexts';

import { ORDER_STATUS, ORDER_SHIPPING_TYPES } from '../utils/orderStatus';
import { getFormattedDates, isPastDate } from '../utils/funcs';

/**
 * Hook to get the texts to display based on the status of the order
 * @param currentAppOrderStatus - The current renderd app status
 * @param deliveryDate - The delivery date
 * @param expectedDeliveryDate - The expected delivery date
 * @param expectedShippingDate - The expected shipping date
 * @param externalTrackingUrl - The tracking url
 * @param orderDate - The date when the order was placed
 * @param orderStatus - The status of the order
 * @param shippingType - The shipping type of the order
 * @param userAddress - The user address of the order
 */
function useOrderTexts({
  currentAppOrderStatus,
  deliveryDate,
  expectedDeliveryDate,
  expectedShippingDate,
  orderDate,
  orderStatus,
  shippingType,
  isShippingDelayed,
}: Omit<OrderItemTextsProps, 'isDelayed'>): {
  delayedParagraph: string;
  firstParagraph: string;
} {
  const { t } = useTranslation('tracking');

  const isMailOrderOrExpress =
    shippingType === ORDER_SHIPPING_TYPES.mailOrder || shippingType === ORDER_SHIPPING_TYPES.express;

  const isCreatedStatus = currentAppOrderStatus === ORDER_STATUS.created;
  const isShippedStatus = currentAppOrderStatus === ORDER_STATUS.shipped;
  const isDeliveredStatus = currentAppOrderStatus === ORDER_STATUS.delivered;

  const isOrderShipped = orderStatus === ORDER_STATUS.shipped;
  const isOrderDelivered = orderStatus === ORDER_STATUS.delivered;

  const isExpectedDeliveryDateInThePast = isPastDate(expectedDeliveryDate);

  const isInProgress = orderStatus === ORDER_STATUS.created || orderStatus === ORDER_STATUS.inProgress;

  const { formattedDeliveryDate, formattedExpectedDeliveryDate, formattedExpectedShippingDate, formattedOrderDate } =
    getFormattedDates({
      deliveryDate,
      expectedDeliveryDate,
      expectedShippingDate,
      orderDate,
    });

  const delayedParagraph = t('delayed');
  let firstParagraph = t(`${currentAppOrderStatus}`);

  if (isCreatedStatus) {
    firstParagraph = `${t(`${currentAppOrderStatus}`)} ${formattedOrderDate}`;
  }

  if (orderStatus === ORDER_STATUS.shipped && currentAppOrderStatus === ORDER_STATUS.inProgress) {
    firstParagraph = t(`printed`);
  }

  if (isShippedStatus && isInProgress) {
    firstParagraph = `${t('toBeShipped')} ${formattedExpectedShippingDate}`;
  }

  if (isShippedStatus && !isInProgress) {
    firstParagraph = `${t(currentAppOrderStatus)} ${formattedExpectedShippingDate}`;
  }

  if (isDeliveredStatus) {
    firstParagraph = `${t('deliveryExpected')} ${formattedExpectedDeliveryDate}`;
  }
  if (isDeliveredStatus && !isMailOrderOrExpress && isOrderShipped && isExpectedDeliveryDateInThePast) {
    firstParagraph = `${t('readyToPickUp')} ${formattedExpectedDeliveryDate}`;
  }
  if (isDeliveredStatus && (isOrderShipped || isOrderDelivered) && !!deliveryDate) {
    firstParagraph = `${t(currentAppOrderStatus)} ${formattedDeliveryDate}`;
  }

  if (isShippingDelayed && isDeliveredStatus) {
    firstParagraph = t('shippingDelayed.title');
  }

  return {
    delayedParagraph,
    firstParagraph,
  };
}

export default useOrderTexts;
