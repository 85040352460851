import React, { useEffect } from 'react';

import { IOrder } from '../../models/Order';

import TrackingOrderItem from './TrackingOrderItem';

import { checkBusinessLogicsFromBackend } from './../../utils/checkBusinessLogicsFromBackend';
import { BackendBusinessLogicCheckError } from './../../utils/errorLevelInfo';
import * as Sentry from '@sentry/browser';
import { useTrackingContext } from '../../hooks/useTrackingContext';

type TrackingListProps = {
  orderData: IOrder;
};

const TrackingList: React.FC<TrackingListProps> = ({ orderData }) => {
  const { orderStatus } = useTrackingContext();
  const status = Object.values(orderStatus);

  /**
   * check consistence of data from backend, in case of issues, send message at sentry
   */
  useEffect(() => {
    const { expectedDeliveryDate, expectedShippingDate, isDelayed } = orderData;
    const { isError, errorMessage, messageName } = checkBusinessLogicsFromBackend({
      expectedDeliveryDate,
      expectedShippingDate,
      isDelayed,
      orderStatus: orderData.status,
    });
    if (isError) {
      const businessLogicCheckException = new BackendBusinessLogicCheckError();
      businessLogicCheckException.message = errorMessage;
      businessLogicCheckException.name = messageName;
      Sentry.captureException(businessLogicCheckException);
    }
  }, [orderData]);

  return (
    <div className="phs-tracking-list pds-grow pds-flex pds-flex-col pds-justify-around">
      {status.map(state => (
        <TrackingOrderItem key={state} itemOrderState={state} orderData={orderData} />
      ))}
    </div>
  );
};

export default TrackingList;
